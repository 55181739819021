'use client';

import { captureException } from '@sentry/nextjs';
import { Metadata } from 'next';
import { FC, useEffect } from 'react';

import { BADGE } from 'src/general/components/BadgeIllustration/BadgeIllustration.type';
import { seoDefaultError } from 'src/general/components/SeoMeta/SeoMeta.helpers';
import { WholePageMessage } from 'src/general/sections/WholePageMessage/WholePageMessage';
import { ErrorPageProps } from 'src/types/appRouter.types';

export const metadata: Metadata = {
  title: seoDefaultError.title,
  description: seoDefaultError.description,
};

const reloadPage = () => {
  if (typeof window !== 'undefined') {
    window.location.reload();
  }
};

const PageError: FC<ErrorPageProps> = ({ error }) => {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
    captureException(error);
  }, [error]);

  return (
    <WholePageMessage
      buttonLabel="Try again"
      buttonOnClick={reloadPage}
      title="Oops, looks like something went wrong"
      iconSrc={BADGE.CARMA_TOUCH}
    />
  );
};

export default PageError;
